<template>
  <div class="about-wrapper">
    <div class="about-team-wrap">
        <div class="about-item-box hidden-md-and-down">
          <el-row :gutter="70">
            <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
              <div class="about-item wow animate__fadeInUp">
                <div class="about-card">
                  <img class="team-img" src="../assets/v3/team/about2.png" alt="team">
                  <div class="team-name">Arnoldyth Rodes Medo</div>
                  <div class="team-position lender-first">Direktur Utama</div>
                  <div class="lender-info-btn">Profil</div>
                  <div class="about-info">
                    <div class="about-info-title">Arnoldyth Rodes Medo</div>
                    <div class="about-info-content">Arnold memperoleh gelar Sarjana Ekonomi dari Universitas Muhammadiyah Yogyakarta, jurusan Akuntansi. Beliau memiliki pengalaman dari beberapa Lembaga Keuangan baik dalam maupun luar negeri sejak tahun 2012 dan juga merupakan sosok pionir dalam dunia Fintech di Indonesia. Hingga saat ini, beliau memfokuskan keahliannya pada beberapa bidang seperti Risk Management, Internal Audit and Fraud Investigation, Sales and Marketing, Market Analysis dan Target Market Analysis.</div>
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
              <div class="about-item wow animate__fadeInUp">
                <div class="about-card">
                  <img class="team-img" src="../assets/v3/team/about1.png" alt="team">
                  <div class="team-name">Satriya Putra</div>
                  <div class="team-position lender-secord">Direktur SDM</div>
                  <div class="lender-info-btn">Profil</div>
                  <div class="about-info">
                    <div class="about-info-title">Satriya Putra</div>
                    <div class="about-info-content">Satriya memperoleh gelar Sarjana Ekonomi dari Universitas Trisakti, Jakarta, jurusan Manajemen. Beliau mempunyai karir cemerlang sebagai Direktur di beberapa perusahaan konsultan bisnis yang berkecimpung di bidang risk management dan Manajemen Informasi Teknologi dengan klien-kiennya baik dari perbankan maupun dari perusahaan jasa keuangan lainnya.</div>
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
              <div class="about-item wow animate__fadeInUp">
                <div class="about-card">
                  <img class="team-img" src="../assets/v3/team/about4.jpg" alt="team">
                  <div class="team-name">Alan Yazid Ali</div>
                  <div class="team-position lender-thrid">Komisaris</div>
                  <div class="lender-info-btn">Profil</div>
                  <div class="about-info">
                    <div class="about-info-title">Alan Yazid Ali</div>
                    <div class="about-info-content">Alan Yazid Ali memperoleh gelar Master di bidang MIS, Bisnis International, dan Manajemen Operasi dari Bowling Green State University, Ohio, America. Dia berperan penting dalam memulai perusahaan dan mengarahkan operasi serta rencana strategisnya. Dia membawa lebih dari 20 tahun pengalaman manajemen risiko dan kepatuhan dalam kepemimpinannya yang panjang dan memainkan peran visioner dalam mengawasi strategi bisnis, pengembangan bisnis, arus bisnis, operasi bisnis, dan dukungan di PT Bank Ekspor Indonesia (persero).Susunan Pengurus Perusahaan berdasarkan Akta Pernyataan Keputusan Rapat PT Smartec Teknologi Indonesia Nomor 6 tanggal 8 September 2022 dan telah mendapat diberitahukan kepada Kementerian Hukum dan HAM melalui surat Penerimaan Pemberitahuan Perubahan Data Perseroan Nomor AHU-AH.01.09-0054861</div>
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
              <div class="about-item wow animate__fadeInUp">
                <div class="about-card">
                  <img class="team-img" src="../assets/v3/team/about3.png" alt="team">
                  <div class="team-name">Ooi Chuan Hock</div>
                  <div class="team-position lender-fourth">Pemegang Saham 72,24%</div>
                  <div class="lender-info-btn">Profil</div>
                  <div class="about-info">
                    <div class="about-info-title">Ooi Chuan Hock</div>
                    <div class="about-info-content">Clement Ooi memperoleh gelar Doktor di bidang Administrasi Bisnis dan manajemen dari University of South Australia. Memiliki peran penting dalam memulai perusahaan dan mengarahkan kegiatan operasional perusahaan serta rencana strategis yang harus dijalani oleh perusahaan. Memiliki lebih dari 20 tahun pengalaman manajemen risiko dan kepatuhan dalam kepemimpinannya yang panjang dan memainkan peran visioner dalam mengawasi strategi bisnis, pengembangan bisnis, arus bisnis, operasi bisnis, dan dukungan di seluruh wilayah Asia-Pasifik dari perusahaan solusi perbankan yang terdaftar di NASDAQ .</div>
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
              <div class="about-item wow animate__fadeInUp">
                <div class="about-card">
                  <img class="team-img" src="../assets/v3/team/about1.png" alt="team">
                  <div class="team-name">Satriya Putra</div>
                  <div class="team-position lender-fifth">Pemegang Saham 27,76%</div>
                  <div class="lender-info-btn">Profil</div>
                  <div class="about-info">
                    <div class="about-info-title">Satriya Putra</div>
                    <div class="about-info-content">Satriya memperoleh gelar Sarjana Ekonomi dari Universitas Trisakti, Jakarta, jurusan Manajemen. Memiliki pengalaman cemerlang pada perusahaan konsultan bisnis yang berkecimpung pada bidang risk management dan Manajemen Informasi Teknologi dengan klien-kiennya baik dari perbankan maupun dari perusahaan jasa keuangan lainnya.</div>
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>       
        </div>
        <div class="m-about-wrap hidden-md-and-up">
          <div class="m-about-item-box">
            <div class="m-about-card" v-if="activeTeam == 2">
              <img class="m-team-img" src="../assets/v3/team/m-about2.png" alt="m-about">
              <img class="m-next-btn" src="../assets/v3/team/next.png" alt="m-next" @click="nextTeam(2)">
              <div class="m-team-name">ARNOLDYTH RODES MEDO</div>
              <div class="m-team-position">Direktur Utama</div>
              <div class="m-team-btn" @click="profile(1)">Profil</div>
            </div>
            <div class="m-about-card" v-if="activeTeam == 3">
              <img class="m-team-img" src="../assets/v3/team/m-about1.png" alt="m-about">
              <img class="m-next-btn" src="../assets/v3/team/next.png" alt="m-next" @click="nextTeam(3)">
              <div class="m-team-name">SATRIYA PUTRA</div>
              <div class="m-team-position">Direktur SDM</div>
              <div class="m-team-btn" @click="profile(2)">Profil</div>
            </div>
            <div class="m-about-card" v-if="activeTeam == 4">
              <img class="m-team-img" src="../assets/v3/team/about4.jpg" alt="m-about">
              <img class="m-next-btn" src="../assets/v3/team/next.png" alt="m-next" @click="nextTeam(4)">
              <div class="m-team-name">ALAN YAZID ALI</div>
              <div class="m-team-position">Komisaris</div>
              <div class="m-team-btn" @click="profile(3)">Profil</div>
            </div>
            <div class="m-about-card" v-if="activeTeam == 5">
              <img class="m-team-img" src="../assets/v3/team/m-about3.png" alt="m-about">
              <img class="m-next-btn" src="../assets/v3/team/next.png" alt="m-next" @click="nextTeam(5)">
              <div class="m-team-name">OOI CHUAN HOCK</div>
              <div class="m-team-position">Pemegang Saham 72,24%</div>
              <div class="m-team-btn" @click="profile(4)">Profil</div>
            </div>
            <div class="m-about-card" v-if="activeTeam == 6">
              <img class="m-team-img" src="../assets/v3/team/m-about1.png" alt="m-about">
              <img class="m-next-btn" src="../assets/v3/team/next.png" alt="m-next" @click="nextTeam(6)">
              <div class="m-team-name">SATRIYA PUTRA</div>
              <div class="m-team-position">Pemegang Saham 27,76%</div>
              <div class="m-team-btn" @click="profile(5)">Profil</div>
            </div>
          </div>
        </div>
    </div>
    <el-dialog
      class="m-dialog-box"
      :visible.sync="dialogVisible"
      width="90%">
      <div class="m-dialog">
        <img class="m-close-btn" @click="dialogVisible = false" src="../assets/v3/team/close-dialog.png" alt="close">
        <div class="m-dialog-name">{{ mName }}</div>
        <div class="m-dialog-content">{{ mContent }}</div>
      </div>
    </el-dialog>
    <div class="laporan-wrap">
      <div class="laporan-card">
        <div class="laporan-box wow animate__fadeInLeft">
          <div class="laporan-title">Laporan</div>
          <div class="keuangan">Keuangan</div>
        </div>
        <div class="smartec wow animate__fadeInLeft">Setiap tahun PT. Smartec Teknologi Indonesia akan selalu melaporkan kondisi keuangannya dan melakukan audit berkala.</div>
        <div class="lihat wow animate__fadeInLeft">Lihat laporan keuangan kami di sini:</div>
        <div class="financial-report-card">
          <el-row :gutter="70">
            <el-col :xs="24" :sm="24" :md="12" :lg="11" :xl="11">
              <div class="financial-item wow animate__fadeInUp" @click="show('2020')">
                <div class="financial-item-title">Laporan Keuangan</div>
                <div class="financial-item-year">2020</div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="11" :xl="11">
              <div class="financial-item wow animate__fadeInUp" @click="show('2021')">
                <div class="financial-item-title">Laporan Keuangan</div>
                <div class="financial-item-year">2021</div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="11" :xl="11">
              <div class="financial-item wow animate__fadeInUp" @click="show('2022')">
                <div class="financial-item-title">Laporan Keuangan</div>
                <div class="financial-item-year">2022</div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="11" :xl="11">
              <div class="financial-item wow animate__fadeInUp" @click="show('2023')">
                <div class="financial-item-title">Laporan Keuangan</div>
                <div class="financial-item-year">2023</div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <div class="financial-wrap">
      <div class="financial-table-card">
          <el-table
            class="financial-table"
            :data="tableData"
            header-align="center"
            stripe
            style="width: 100%">
            <el-table-column width="70px" prop="id" label="No" />
            <el-table-column
              prop="Nama"
              label="Nama Kegiatan" />
            <el-table-column
              align="center"
              prop="Tempat"
              label="Tempat" />
            <el-table-column
              align="right"
              prop="Tanggal"
              label="Tanggal Kegiatan" />
          </el-table>
        </div>
    </div>
  </div>
</template>

<script>
import info from '@/utils/options'
import {WOW} from 'wowjs'
export default {
  data() {
    return {
      tableData: [
        { id: 1,
          Nama: '"Mengakses Layanan Pinjam Meminjam Berbasis Teknologi untuk UMKM Bersama Dinas Koperasi dan UMKM Kota Medan"',
          Tempat: 'Hotel Grand Aston, Medan',
          Tanggal: '13 Februari 2020'},
        { id: 2,
          Nama: '"Inovasi Keuangan Startup Fintech Indonesia Bersama Mahasiswa/i Universitas Bengkulu"',
          Tempat: 'Universitas Bengkulu',
          Tanggal: '14 Februari 2020'},
        { id: 3,
          Nama: '"Menjembatani Kebutuhan Pembiayaan Pengusaha Milenial Bersama HIPMI Kepulauan Riau"',
          Tempat: 'Hotel Grand Aston, Medan',
          Tanggal: '20 Februari 2020'},
        { id: 4,
          Nama: '"Fineast Kupang Bersama AFPI dan OJK"',
          Tempat: 'Hotel Aston, Kupang',
          Tanggal: '27-28 Februari 2020'},
        { id: 5,
          Nama: '"Ekosistem Fintech"',
          Tempat: 'Kuningan, Jakarta (via zoom meeting)',
          Tanggal: '07 Desember 2020'},
        { id: 6,
          Nama: '"Landscape Fintech Indonesia"',
          Tempat: 'Kuningan, Jakarta (via zoom meeting)',
          Tanggal: '08 Desember 2020'},
        { id: 7,
          Nama: '"Mengakses Keuangan Alternatif E-Commerce"',
          Tempat: 'Tempat: Kuningan, Jakarta (via zoom meeting)	',
          Tanggal: '14 Desember 2020'},
        { id: 8,
          Nama: '"Aspek-Aspek Legal P2P Lending"',
          Tempat: 'Kuningan, Jakarta (via zoom meeting)',
          Tanggal: '15 Desember 2020'},
        {
          id: 9,Nama: '"Managing your Budget for Individual Business"',
          Tempat: 'Kuningan, Jakarta (via zoom meeting)',
          Tanggal: '25 Januari 2021'},
        { id: 10,
          Nama: '"How to Manage your Personal Finance"',
          Tempat: 'Kuningan, Jakarta (via zoom meeting)',
          Tanggal: '9 Februari 2021'},
        { id: 11,
          Nama: '"Press Conference - Peran Fintech dalam Pemulihan Ekonomi Nasional"',
          Tempat: 'Tempat: Kuningan, Jakarta (via zoom meeting)',
          Tanggal: '23 Februari 2021'},
        { id: 12,
          Nama: '"Artificial Intelligence in Alternative Credit Scoring"',
          Tempat: 'Kuningan, Jakarta (via zoom meeting)',
          Tanggal: '02 Maret 2021'},
        { id: 13,
          Nama: '"Danjen Kopassus x HIPMI Jaya PUBG Mobile Cup 2022"',
          Tempat: 'TNI AD Kopassus, Kota Jakarta',
          Tanggal: '14 Juni 2022'},
        { id: 14,
          Nama: '"BUMN Start Up Day"',
          Tempat: 'ICE BSD, Kota Tanggerang',
          Tanggal: '26 - 28 September 2022'},
        { id: 15,
          Nama: '"OJK Goes To Belitung - Pinjaman Online : Manfaat dan Resiko Bagi Masyarakat"',
          Tempat: 'BW Suite Hotel, Kota Belitung',
          Tanggal: '21 Oktober 2022'},
        { id: 16,
          Nama: '"Sosialisasi dan Edukasi BantuSaku - Mengakselerasi Pemberdayaan Ekonomi Rakyat dengan Peningkatan Literasi Keuangan & Digital"',
          Tempat: 'Asrama Haji, Kota Palembang',
          Tanggal: '24 Oktober 2022'},
        { id: 17,
          Nama: '"Go Inklusif Go Produktif - Pameran Lembaga Jasa Keuangan"',
          Tempat: 'Mall Central Park, Laguna Lobby LG Floor, Jakarta Barat',
          Tanggal: '26 - 30 Oktober 2022'},
        { id: 18,
          Nama: `"AFPI CEO's Mind - Business, Financial, and Economic Outlook 2023"`,
          Tempat: 'Hotel Indonesia Kempinski Jakarta',
          Tanggal: '19 Desember 2023'},
        { id: 19,
          Nama: `"Festival Olahraga Rekreasi Nasional (FORNAS) VII Jawa Barat - Indonesia Esports Association (IESPA) Melodies Of Victory"`,
          Tempat: 'Sudirman Grand Ballroom, Bandung',
          Tanggal: '5-6 Juli 2023'},
        { id: 20,
          Nama: `"Digital Investment Forum- P2SK Law And Digital Investment Potential in Indonesia"`,
          Tempat: 'Ballroom Bali Hotel Kempinski Jakarta',
          Tanggal: '20 Juli 2023'},
        { id: 21,
          Nama: `"AFPI Fintech Sport Days 2023"`,
          Tempat: 'Jakarta',
          Tanggal: '15 Juli - 19 Agustus 2023'},
        { id: 22,
          Nama: `"Fintech Lending Days AFPI"`,
          Tempat: 'Hotel Santika Premiere Dyandra, Medan',
          Tanggal: '6-7 Mei 2024'},
        { id: 23,
          Nama: `"FinExpo 2024 Kota Balikpapan"`,
          Tempat: 'Pentacity/E-Walk Mall Kota Balikpapan',
          Tanggal: '3-6 Oktober 2024 '},
      ],
      activeTeam: 2,
      dialogVisible: false,
      infoOptions: info.aboutInfo,
      mName: '',
      mContent: ''
    };
  },
  mounted(){
    this.$nextTick(() => {
      var wow = new WOW({
        animateClass: 'animated',
        offset: 0,
      })
      wow.init()
    })
    sessionStorage.setItem('activeTab', '3')
  },
  methods:{
    nextTeam(active){
      if(active < 6){
        this.activeTeam = active + 1
      } else {
        this.activeTeam = 2
      }
    },
    profile(index){
      this.dialogVisible = true
      this.mName = this.infoOptions[index].name
      this.mContent = this.infoOptions[index].info
    },
    show(year){
      switch(year){
        case '2020':
          window.open('https://drive.google.com/file/d/16tg4eu9aX7ZW6QE0Qp5NhN99CJ6k_uCK/view')
          break;
        case '2021':
          window.open('https://drive.google.com/file/d/1byjBdaL1QYmqCGFs-X_7BFr6Vhifu-fE/view?usp=sharing')
          break;
        case '2022':
          window.open('https://drive.google.com/file/d/1EN2EONIq6a80dHZ1eiTzYzTM2P2O6t6f/view?usp=sharing')
          break;
        case '2023':
          window.open('https://drive.google.com/file/d/1_0HyLftlQELaxjBHpdOBhFBEOPf0DooZ/view?usp=drivesdk')
          break;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.about-wrapper{
  width: 100%;
  background: #fff;
  overflow: hidden;
  .team-total-wrap{
    width: 100%;
    background: #C6E8ED;
  }
  .about-team-wrap{
    background: #C6E8ED;
    padding: 174px 0 0 0;
    box-sizing: border-box;
    overflow: hidden;
    .about-item-box{
      max-width: 1400px;
      margin: 0 auto;
      .about-item{
        position: relative;
        .about-card{
          width: 425px;
          height: 305px;
          background: url("../assets/v3/team/about-item-bg.png") no-repeat;
          background-size: 100% 100%;
          position: relative;
          padding: 72px 0 0 61px;
          box-sizing: border-box;
          margin-bottom: 85px;
          position: relative;
          cursor: pointer;
          .team-img{
            width: 143px;
            height: 143px;
            border-radius: 50%;
            position: absolute;
            left: 80px;
            top: -79px;
          }
          .team-name{
            width: 288px;
            font-size: 38px;
            font-weight: bold;
            line-height: 44px;
          }
          .team-position{
            width: 288px;
            font-size: 18px;
            font-weight: 500;
          }
          .lender-first{
            margin: 6px 0 10px 0;
          }
          .lender-secord{
            margin: 30px 0 28px 0;
          }
          .lender-thrid{
            margin: 32px 0 24px 0;
          }
          .lender-fourth{
            margin: 6px 0 10px 0;
          }
          .lender-fifth{
            margin: 30px 0 28px 0;
          }
          .lender-info-btn{
            width: 212px;
            height: 60px;
            line-height: 60px;
            text-align: center;
            font-size: 18px;
            color: #145246;
            border: 1px solid #145246;
            border-radius: 30px;
            font-weight: 600;
          }
          .about-info{
            width: 100%;
            min-height: 400px;
            background: rgba(40, 84, 63, 0.8);
            border-radius: 23px 23px 0px 0px;
            position: absolute;
            left: 0;
            bottom: -49px;
            visibility: hidden;
            padding: 26px 20px 26px 20px;
            box-sizing: border-box;
            color: #fff;
            .about-info-title{
              font-size: 24px;
              font-weight: 600;
              margin-bottom: 10px;
            }
            .about-info-content{
              font-size: 12px;
              line-height: 25px;
            }
          }
        }
        .about-card:hover .about-info{
          visibility: visible;
        }
      }
    }
  }
  .m-about-wrap{
    width: 100%;
    min-height: 310px;
    background: #C6E8ED;
    padding: 90px 26px 36px 26px;
    box-sizing: border-box;
    .m-about-card{
      width: 90%;
      min-height: 180px;
      background: url("../assets/v3/team/m-about-item-bg.png") no-repeat;
      background-size: 100% 100%;
      position: relative;
      padding: 55px 100px 21px 46px;
      box-sizing: border-box;
      .m-team-img{
        width: 100px;
        height: 100px;
        position: absolute;
        top: -70px;
        left: 56px;
        border-radius: 50%;
      }
      .m-next-btn{
        width: 32px;
        height: 32px;
        position: absolute;
        right: -23px;
        top: 84%;
        cursor: pointer;
      }
      .m-team-name{
        font-size: 20px;
        font-weight: bold;
        padding-left: 10px;
        box-sizing: border-box;
        line-height: 20px;
      }
      .m-team-position{
        font-size: 12px;
        font-weight: 600;
        padding-left: 10px;
        box-sizing: border-box;
        margin: 4px 0 12px 0;
      }
      .m-team-btn{
        max-width: 128px;
        font-size: 12px;
        font-weight: 600;
        padding: 12px 44px;
        border: 1px solid #145246;
        box-sizing: border-box;
        cursor: pointer;
        color: #145246;
        border-radius: 24px;
      }

    }
  }

  .m-dialog-box{
    .m-dialog{
      width: 100%;
      min-height: 280px;
      background: #fff;
      border-radius: 8px!important;
      padding: 25px 20px 25px 20px;
      box-sizing: border-box;
      position: relative;
      color: #000;
      top: 30% !important;
      .m-close-btn{
        width: 24px;
        height: 24px;
        position: absolute;
        right: 10px;
        top: -32px;
      }
      .m-dialog-name{
        font-size: 26px;
        font-weight: 600;
        margin-bottom: 6px;
      }
      .m-dialog-content{
        font-size: 12px;
        line-height: 19px;
      }
    } 
  }
  .laporan-wrap{
    width: 100%;
    padding-bottom: 80px;
    background: url("../assets/v3/team/team-banner.png") no-repeat;
    background-size: 100% 200%;
    background-position: top;
    margin-top: 30px;
    box-sizing: border-box;
    .laporan-card{
      max-width: 1400px;
      margin: 0 auto;
      box-sizing: border-box;
    }
    .laporan-box{
      display: flex;
      align-items: center;
      .laporan-title{
        font-size: 44px;
        font-weight: bold;
        color: #1D1D1D;
        margin-right: 10px;
      }
      .keuangan{
        font-weight: bold;
        font-size: 44px;
        color: #2E5A44;
      }
    }
    .smartec{
      width: 980px;
      font-size: 34px;
      line-height: 46px;
    }
    .lihat{
      font-size: 44px;
      font-weight: 600;
      margin: 48px 0 40px 0;
    }
    .financial-report-card{
      padding-left: 10%;
      .financial-item{
        width: 80%;
        height: 100px;
        background: #ACDDB1;
        border-radius: 83px;
        color: #28543F;
        font-weight: 600;
        text-align: center;
        box-sizing: border-box;
        margin-bottom: 20px;
        cursor: pointer;
        transition: all 0.35s ease;
        display: flex;
        align-items: center;
        justify-content: space-around;
        .financial-item-title{
          font-size: 26px;
        }
        .financial-item-year{
          font-size: 26px;
        }
      }
      .financial-item:hover{
        transform: translateY(5px);
      }

    }
  }
  .financial-wrap{
    width: 100%;
    background: #C6E8ED;
    .financial-table-card{
      width: 1400px;
      padding: 60px 60px 60px 60px;
      box-sizing: border-box;
      margin: 0 auto;
      .financial-table{
        width: 100%;
        cursor: pointer;
      }
    }
  }
}
</style>